import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export const LoadingSpinner = () => {
  return (
    <div className="modal-container">
      <CircularProgress style={{ color: "orange" }} />
    </div>
  );
};
