import React from "react";
import "./../assets/css/bidModal.css";
import axios from "../axios";
import createAndSubmitCybersourceForm from "../utils/createAndSubmitCybersourceForm";
import { toast } from "react-toastify";
export const BidList = ({
  name,
  setSelectedName,
  notExpired,
  currency,
  multiplier,
}) => {
  const [bistList, setBidList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    axios
      .get(`api/v3/names/bid-list`, {
        params: {
          name: `${name.name}`,
        },
      })
      .then((res) => {
        setBidList(res.data.data);
      });
  }, []);

  const onPaymentConfirm = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await axios.post(`api/v3/names/bid-confirmation`, {
      name: name.name,
      phoneNumber: e.target[0].value,
    });
    if (res.data.status && res.data.data) {
      createAndSubmitCybersourceForm(res.data.data);
    } else {
      console.log("show tast");
      toast.warn("Sorry, you are not eligible to proceed further");
    }
    setLoading(false);
  };

  return (
    <div className="modal-container">
      <div className="bid-modal">
        <div className="modal-header d-flex flex-column">
          <h4>{name.name}</h4>
          {/* <div className="d-flex w-100">
            <span>Total Bids :</span>
            <span className="ml-2">{name.totalBids}</span>
          </div>
          <div className="d-flex w-100 mt-1">
            <span>Highest Bid :</span>
            <span className="ml-2">{name.highestBidAmount} NPR</span>
          </div> */}
        </div>
        {/* <div>{bidExpiresIn(name.expireAt)}</div> */}
        <div className="modal-body">
          <div className="bid-list-container">
            <table className="table table-striped">
              <thead className="position-sticky top-0">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Number</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {bistList.map((bidder, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{bidder.phoneNumber}</td>
                      <td>
                      {currency === "NPR"
                          ? bidder.bidNPRAmount
                          : bidder.bidNPRAmount / 1.6}{" "}
                        {currency}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <form onSubmit={onPaymentConfirm}>
            <div className="form-group">
              <label>
                Check with your phone number, if you have won the bid you will
                be redirected to payment page.
              </label>
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  type="tel"
                  placeholder="Enter Your Phone Number"
                  required
                  name="number"
                />
              </div>
            </div>
            <button
              className="btn btn-primary mt-3"
              disabled={loading || notExpired}
            >
              Confirm
            </button>
            <button
              className="btn btn-secondary mt-3 ml-3"
              onClick={(event) => {
                event.preventDefault();
                setSelectedName({});
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
