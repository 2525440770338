import React from "react";
import "./../assets/css/bidModal.css";
import axios from "../axios";
import { LoadingSpinner } from "../Components/loadingSpinner";
import createAndSubmitCybersourceForm from "../utils/createAndSubmitCybersourceForm";
export const Bid = ({ name, setSelectedName, currency }) => {
  const [bidParameter, setBidParameter] = React.useState({});
  const [number, setNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    axios
      .get(`api/v3/names/bid-amount`, {
        params: {
          name: name.name,
          countryCode: currency === "INR" ? "+91" : "+977",
        },
      })
      .then((res) => {
        setBidParameter(res.data);
        setAmount(res.data.minBidAmount);
      });
  }, [loading]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setMessage("");

    if (name === "number") {
      if (!/^$|^[0-9]+$/.test(value)) {
        return;
      }
      setNumber(value);
    }
    if (name === "amount") {
      if (!/^$|^[0-9]+$/.test(value)) {
        return;
      }
      setAmount(value);
    }
  };

  const bid = (event) => {
    event.preventDefault();
    if (!number) {
      return setMessage("Please enter your Phone Number.");
    }
    // if (number.length !== 10) {
    //   return setMessage("Invalid phone number.");
    // }

    if (!amount) {
      return setMessage("Please enter amount.");
    }
    if (!(amount >= bidParameter.minBidAmount)) {
      return setMessage("Invalid amount.");
    }
    setLoading(true);
    axios
      .post("api/v3/names/bid-name", {
        name: name.name,
        phoneNumber: number,
        amount,
        countryCode: currency === "INR" ? "+91" : "+977",
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          createAndSubmitCybersourceForm(res.data.data);
        } else {
          setMessage("Something went wrong.");
        }
      });
  };

  return (
    <div className="modal-container">
      <div className="bid-modal" style={{ minHeight: "50vh" }}>
        <div className="modal-header">
          <div className="d-flex w-100 flex-column">
            <h3 style={{ color: "#ff900a" }}>Raise Bid</h3>
            <h5 className="mt-2">{name.name}</h5>
            {/* <div>
              {" "}
              <span>Highest Bid: </span>
              <span className="ml-1">{name.highestBid} NPR</span>
            </div> */}
          </div>
        </div>
        <div className="modal-body">
          {loading && <LoadingSpinner />}
          {message.length > 0 && <div className="show-message">{message}</div>}
          <form>
            <div className="form-group">
              <label className="font-weight-light">Phone Number</label>
              <div className="input-group mb-3 w-75">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {currency === "NPR" ? "+977" : "+91"}
                  </span>
                </div>
                <input
                  className="form-control w-75"
                  type="tel"
                  placeholder="Enter Your Phone Number"
                  required
                  name="number"
                  value={number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group text-center">
              <label className="font-weight-light">Amount</label>
              <input
                className="form-range"
                type="range"
                placeholder="Enter Bid Amount"
                min={bidParameter.minBidAmount}
                max={parseFloat(bidParameter.minBidAmount) * 10}
                step={bidParameter.bidIncrementAmount}
                required
                name="amount"
                onChange={handleChange}
              />
              {amount}
            </div>
            {/* <div className="form-group">
              <div className="d-flex w-75 justify-content-between p-2">
                <div className="text-danger font-weight-bold small">
                  Minimum bid amount: {bidParameter.minBidAmount} NPR
                </div>
                <div className="text-danger font-weight-bold small">
                  Maximum bid amount: {bidParameter.maxBidAmount} NPR
                </div>
              </div>
            </div> */}
            <div>
              Bid Entry fee: {bidParameter.bidEntryFee} {currency}
            </div>

            <div className="form-group mt-2">
              <button
                className="btn btn-primary mt-2"
                onClick={bid}
                type="submit"
              >
                Bid
              </button>
              <button
                className="btn btn-text ml-2"
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedName({});
                }}
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
