export default async function createAndSubmitCybersourceForm(body) {
  let form = document.createElement("form");
  form.setAttribute("method", body.method);
  form.setAttribute("action", body.url);
  for (let key in body.params) {
    let input = document.createElement("input");
    input.setAttribute("name", key);
    input.setAttribute("value", body.params[key]);
    form.appendChild(input);
  }
  form.style.display = "none";
  document.body.appendChild(form);
  form.submit();
}
